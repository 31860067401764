import React from "react";
import _ from "lodash";
import { useState, useEffect, useContext } from "react";
import { Router, Route } from "react-router-dom";

import {
  grommet,
  Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Grid,
  Grommet,
  ResponsiveContext,
  Text,
} from "grommet";

import { FoldingCube } from "styled-spinkit";

import {
  Search,
  Header,
  Segment,
  Label,
  Button,
  Loader,
  Container,
  Divider,
} from "semantic-ui-react";

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";

import { useMediaQuery, useMediaQueries } from "@react-hook/media-query";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// import { Row, Col } from "react-flexa";
import { Flex } from "@adobe/react-spectrum";

import PieChart from "../CardView/PieChart";

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { userJsonRecoil } from "../NavigationBar/api/state";

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Animation

//API
import {
  watchlist_GET,
  watchlist_POST,
  get_predict_POST,
  search_stock_with_name_POST,
  recommendation_POST,
} from "./api/actions/query";

import { get_user_GET } from "../NavigationBar/api/actions/query";

const token = "quanting_io_token";

const buttons = ["1m", "5m", "15m", "30m", "1h", "2h", "4h", "1d", "1W", "1M"];

let selected_index = 7;

// const ddd = ({ss: string} )

const MainView = (props: any) => {
  // console.log(props);

  let userId = "";
  // State
  const [userJson, setUserJson] = useRecoilState(userJsonRecoil);
  const [watchListCount, setWatchListCount] = React.useState(0);
  // const [selected_index, set_selected_index] = React.useState(7);
  const [alignment, setAlignment] = React.useState(buttons[selected_index]);
  const [predict_json, set_predict_json] = React.useState([]);
  const [recommendation_json, set_recommendation_json] = React.useState([]);
  const [stock_name, set_stock_name] = React.useState([]);
  const [loading, set_loading] = React.useState(false);
  const matches = useMediaQuery("only screen and (min-width: 1024px)");
  const onlyWidth = useWindowWidth();

  const resultRenderer = ({ title, symbol, exchange }: any) => (
    <>
      <Label content={title} /> <Label content={symbol} />
      <Label content={exchange} />
    </>
  );

  const handleChange = (event: any, newAlignment: any) => {
    console.log(event);
    console.log(newAlignment);

    if (!newAlignment) {
      console.log("return");
      return;
    }

    setAlignment(newAlignment);

    switch (newAlignment) {
      case buttons[0]:
        selected_index = 0;
        break;

      case buttons[1]:
        selected_index = 1;
        break;

      case buttons[2]:
        selected_index = 2;
        break;

      case buttons[3]:
        selected_index = 3;
        break;

      case buttons[4]:
        selected_index = 4;
        break;

      case buttons[5]:
        selected_index = 5;
        break;

      case buttons[6]:
        selected_index = 6;
        break;

      case buttons[7]:
        selected_index = 7;
        break;

      case buttons[8]:
        selected_index = 8;
        break;

      case buttons[9]:
        selected_index = 9;
        break;

      default:
        break;
    }

    requestData(newAlignment);
  };

  const handleSearchChange = React.useCallback((e: any, data: any) => {
    console.log("handleSearchChange");
    set_stock_name([]);
    search_stock_with_name(data["value"]);
  }, []);

  let search_stock_with_name = (equity: string) => {
    return search_stock_with_name_POST(equity).then((response) => {
      if (response.data[0] === "No Results Found") {
        set_stock_name([]);
      } else {
        set_stock_name(response.data);
      }
    });
  };

  const recommendation_stock = async () => {
    if (
      userId === "61860fce89411ffee6cfd694" ||
      userId === "620879c8891be5913dd4de63"
    ) {
      await recommendation_POST().then((response) => {
        // console.log(response?.data?.recommendation);
        response?.data?.recommendation.map((item: any) => {
          get_predict_POST({
            symbol: item.split(":")[1],
            screener: "america",
            exchange: item.split(":")[0],
            interval: "1d",
            title: item.split(":")[1],
          })
            .then((response) => {
              console.log(response.data);
              // Object.assign(response.data, { _id: item._id });
              // set_recommendation_json((json) => [...json, response.data]);

              set_recommendation_json((recommendation_json) => [
                ...recommendation_json,
                response.data,
              ]);

              console.log(recommendation_json);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      });
    }
  };

  let requestData = (value = "1d") => {
    set_loading(true);

    set_predict_json([]);

    set_recommendation_json([]);

    watchlist_GET(userJson).then(async (response) => {
      setWatchListCount(response?.data.length);

      response?.data.map((item: any) => {
        Object.assign(item, { interval: value });

        get_predict_POST(item)
          .then((response) => {
            Object.assign(response.data, { _id: item._id });
            set_predict_json((predict_json) => [
              ...predict_json,
              response.data,
            ]);
            console.log(predict_json);
          })
          .catch((error) => {
            console.log(error);
            set_predict_json((predict_json) => [
              ...predict_json,
              { _id: item._id, setting_value: { title: "Error" } },
            ]);
          });
      });

      set_loading(false);
    });
  };

  const checkLogin = async () => {
    console.log("checkLogin");
    console.log(document.cookie.includes(token));
    if (document.cookie.includes(token)) {
      console.log("token");

      await get_user_GET().then((temp_response) => {
        console.log(temp_response.data);
        userId = temp_response.data?._id;
        setUserJson(temp_response.data);

        watchlist_GET(temp_response.data).then(async (response) => {
          setWatchListCount(response.data.length);

          response?.data.map((item: any) => {
            Object.assign(item, { interval: buttons[selected_index] });

            get_predict_POST(item)
              .then((response) => {
                Object.assign(response.data, { _id: item._id });
                set_predict_json((predict_json) => [
                  ...predict_json,
                  response.data,
                ]);
              })
              .catch((error) => {
                console.log(error);
                set_predict_json((predict_json) => [
                  ...predict_json,
                  { _id: item._id, setting_value: { title: "Error" } },
                ]);
              });
          });
        });

        set_loading(false);
      });

      await recommendation_stock();
    }
  };

  useEffect(() => {
    console.log("MainView useEffect");

    console.log(userJson);

    set_loading(true);

    set_predict_json([]);

    checkLogin();
  }, []);

  return (
    <div style={{ backgroundColor: "#1F2A3E", marginTop: "5px" }}>
      <Search
        fluid
        style={{
          display: "flex",
          marginRight: "10px",
          backgroundColor: "#1F2A3E",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "end",
          marginBottom: "15px",
        }}
        resultRenderer={resultRenderer}
        size="mini"
        onResultSelect={(e, data) => {
          watchlist_POST(data, userJson).then((response) => {
            console.log(response.data);
            requestData();
          });

          console.log("onResultSelect", data?.result);
        }}
        // onResultSelect={(e, data) =>
        //   dispatch({ type: "UPDATE_SELECTION", selection: data.result.title })
        // }
        // onResultSelect={(e, data) =>
        //   dispatch({ type: "UPDATE_SELECTION", selection: data.result.title })
        // }
        onSearchChange={handleSearchChange}
        results={stock_name}
        // value={value}
      />
      {/* predict_json.length */}
      <div
        style={{
          display: "flex",
          // backgroundColor: "darkgrey",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <ToggleButtonGroup
          style={{
            width: matches ? 1024 : onlyWidth,
            marginTop: "5px",
          }}
          disabled={predict_json.length === watchListCount ? false : true}
          color="primary"
          value={alignment}
          size="small"
          exclusive
          fullWidth
          onChange={handleChange}
        >
          <ToggleButton
            style={{
              fontSize: 14,
              color: alignment === buttons[0] ? "white" : "grey",
              borderColor: alignment === buttons[0] ? "white" : null,
              borderRadius: "8px",
            }}
            value={buttons[0]}
          >
            {buttons[0]}
          </ToggleButton>

          <ToggleButton
            style={{
              fontSize: 14,
              color: alignment === buttons[1] ? "white" : "grey",
              borderColor: alignment === buttons[1] ? "white" : null,
              borderRadius: "8px",
            }}
            value={buttons[1]}
          >
            {buttons[1]}
          </ToggleButton>

          <ToggleButton
            style={{
              fontSize: 14,
              color: alignment === buttons[2] ? "white" : "grey",
              borderColor: alignment === buttons[2] ? "white" : null,
              borderRadius: "8px",
            }}
            value={buttons[2]}
          >
            {buttons[2]}
          </ToggleButton>

          <ToggleButton
            style={{
              fontSize: 14,
              color: alignment === buttons[3] ? "white" : "grey",
              borderColor: alignment === buttons[3] ? "white" : null,
              borderRadius: "8px",
            }}
            value={buttons[3]}
          >
            {buttons[3]}
          </ToggleButton>

          <ToggleButton
            style={{
              fontSize: 14,
              color: alignment === buttons[4] ? "white" : "grey",
              borderColor: alignment === buttons[4] ? "white" : null,
              borderRadius: "8px",
            }}
            value={buttons[4]}
          >
            {buttons[4]}
          </ToggleButton>

          <ToggleButton
            style={{
              fontSize: 14,
              color: alignment === buttons[5] ? "white" : "grey",
              borderColor: alignment === buttons[5] ? "white" : null,
              borderRadius: "8px",
            }}
            value={buttons[5]}
          >
            {buttons[5]}
          </ToggleButton>

          <ToggleButton
            style={{
              fontSize: 14,
              color: alignment === buttons[6] ? "white" : "grey",
              borderColor: alignment === buttons[6] ? "white" : null,
              borderRadius: "8px",
            }}
            value={buttons[6]}
          >
            {buttons[6]}
          </ToggleButton>

          <ToggleButton
            style={{
              fontSize: 14,
              color: alignment === buttons[7] ? "white" : "grey",
              borderColor: alignment === buttons[7] ? "white" : null,
              borderRadius: "8px",
            }}
            value={buttons[7]}
          >
            {buttons[7]}
          </ToggleButton>

          <ToggleButton
            style={{
              fontSize: 14,
              color: alignment === buttons[8] ? "white" : "grey",
              borderColor: alignment === buttons[8] ? "white" : null,
              borderRadius: "8px",
            }}
            value={buttons[8]}
          >
            {buttons[8]}
          </ToggleButton>

          <ToggleButton
            style={{
              fontSize: 14,
              color: alignment === buttons[9] ? "white" : "grey",
              borderColor: alignment === buttons[9] ? "white" : null,
              borderRadius: "8px",
            }}
            value={buttons[9]}
          >
            {buttons[9] + "th"}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div
        style={{
          backgroundColor: "black",
          width: "100%",
          height: 1,
          marginTop: 10,
        }}
      ></div>

      {loading ? <FoldingCube color="white" size={20} /> : null}

      <Flex direction="row" gap="size-100" justifyContent="center" wrap>
        {predict_json
          .sort((a, b) =>
            a?.setting_value?.title.localeCompare(b?.setting_value?.title)
          )
          .map((data, index) => (
            <Box pad="xxsmall" key={index}>
              <PieChart
                data={data}
                index={index}
                requestData={() => requestData()}
              />
            </Box>
          ))}
      </Flex>

      <Divider />

      <Flex direction="row" gap="size-100" justifyContent="center" wrap>
        {recommendation_json
          .sort((a, b) =>
            a?.setting_value?.title.localeCompare(b?.setting_value?.title)
          )
          .map((data, index) => (
            <Box pad="xxsmall" key={index + data}>
              <PieChart
                data={data}
                index={index}
                requestData={() => requestData()}
              />
            </Box>
          ))}
      </Flex>
    </div>
  );
};

export default MainView;

// #EF5350
// #862226
// #444651
// #133A87
// #2862FF
