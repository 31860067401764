import React from "react";
import ReactDOM from "react-dom";
import MainView from "./views/Main/MainView";
import AppNavbar from "./views/NavigationBar/AppNavbarView";
import WelcomeView from "./views/WelcomeView";

import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
// import { Provider } from "react-redux";
import configureStore from "./redux/store/configureStore";
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";
import { LightTheme, BaseProvider, styled, DarkTheme } from "baseui";
import "semantic-ui-css/semantic.min.css";
import "./API/intercepter";
import { CookiesProvider } from "react-cookie";
import "katex";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import { Provider, defaultTheme, Button } from "@adobe/react-spectrum";
const engine = new Styletron();

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

ReactDOM.render(
  <StyletronProvider value={engine}>
    <BaseProvider theme={LightTheme}>
      <RecoilRoot>
        <Provider theme={defaultTheme}>
          <CookiesProvider>
            <BrowserRouter>
              <AppNavbar />
              <Routes>
                <Route path="/welcome" element={<WelcomeView />} />
                <Route path="/" element={<MainView />} />
              </Routes>
            </BrowserRouter>
          </CookiesProvider>
        </Provider>
      </RecoilRoot>
    </BaseProvider>
  </StyletronProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
