import React from "react";

import {
  Search,
  Header,
  Segment,
  Label,
  Button,
  Icon,
  Modal,
} from "semantic-ui-react";

import {
  grommet,
  Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Grid,
  Grommet,
  ResponsiveContext,
  Text,
} from "grommet";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { headerBG, SELL, BUY } from "../../colorValue";

//API
import { watchlist_DELETE } from "../Main/api/actions/query";

function PieChart(props) {
  const { data, index, requestData } = props;

  return (
    <div>
      <Card height="small" width="small" background="#1F2A3E" key={index}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Text style={{ margin: 6, fontSize: 16 }}>
            {data?.setting_value?.symbol?.substring(0, 15)}
          </Text>

          <Modal
            trigger={
              <Icon
                style={{ margin: 6, color: SELL }}
                name="trash alternate outline"
              />
            }
            basic
            size="small"
          >
            <Header icon="archive" content="Delete Stock" />
            <Modal.Content>
              <p>Is Really Delete Stock?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="red"
                onClick={() => {
                  watchlist_DELETE(data?._id).then(() => requestData());
                }}
                inverted
              >
                <Icon name="checkmark" /> Delete
              </Button>
            </Modal.Actions>
          </Modal>
        </div>

        <Text
          style={{ marginLeft: 6, fontSize: 16 }}
          color={
            data?.indicators?.change.toFixed(0).includes("-") === true
              ? SELL
              : BUY
          }
        >
          {data?.indicators?.change.toFixed(2)}%
          {"   " + data?.indicators?.close.toFixed(0)}
        </Text>

        <CircularProgressbar
          strokeWidth={
            data?.summary?.RECOMMENDATION.includes("STRONG") === true ? 15 : 5
          }
          value={Math.abs(
            parseInt(((data?.summary?.BUY - data?.summary?.SELL) / 26) * 100)
          )}
          text={`${Math.abs(
            parseInt(((data?.summary?.BUY - data?.summary?.SELL) / 26) * 100)
          )}%`}
          circleRatio={0.75}
          styles={buildStyles({
            pathColor:
              parseInt(
                ((data?.summary?.BUY - data?.summary?.SELL) / 26) * 100
              ) < 0
                ? SELL
                : BUY,
            rotation: 1 / 2 + 1 / 8,
            trailColor: "#3D5874",
          })}
        />

        {/* <div style={{ marginTop: 14 }}></div> */}
        <CardFooter pad={{ horizontal: "small" }} background="#2B4559">
          {data?.summary?.RECOMMENDATION.replace("_", " ")}
        </CardFooter>
      </Card>
    </div>
  );
}

export default PieChart;
