// Imports
import axios from "axios";
import { SERVER_URL, ML_URL } from "../../../../config/config";

export const get_predict_POST = async (body) => {
  return await axios.post(SERVER_URL + "/stock/predict", body, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const search_stock_with_name_POST = async (body) => {
  return await axios.post(
    SERVER_URL + "/stock/search-stock-with-name",
    { searchString: body },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
};

export const watchlist_GET = async (data) => {
  console.log(data);
  if (data === undefined || data === null) {
    return;
  }

  return await axios.get(SERVER_URL + "/api/watchlist" + "/" + data._id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const recommendation_POST = () => {
  return axios.post(SERVER_URL + "/stock/recommendation", {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const watchlist_POST = async (data, user) => {
  return await axios.post(
    SERVER_URL + "/api/watchlist",
    {
      userId: user._id,
      exchange: data?.result?.exchange,
      interval: "1d",
      screener: "america",
      title: data?.result?.title,
      symbol: data?.result?.symbol,
    },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
};

export const watchlist_DELETE = async (objectId) => {
  return await axios.delete(SERVER_URL + "/api/watchlist" + "/" + objectId, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// export const getUpcomingEvent = async (userJson) => {
//   const token = await AsyncStorage.getItem("@token_Key");
//   return await axios.get(
//     SERVER_URL + "/api/purchase/getUpcomingEvent/" + userJson._id,
//     {
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     }
//   );
// };

// export const getMyEventTime = async (userJson) => {
//   const token = await AsyncStorage.getItem("@token_Key");
//   return await axios.get(
//     SERVER_URL +
//       "/api/purchase/getMyEventTime/" +
//       userJson._id +
//       "/" +
//       userJson.roomId,
//     {
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     }
//   );
// };

// export const getHighlights = async () => {
//   const token = await AsyncStorage.getItem("@token_Key");

//   return await axios.get(SERVER_URL + "/api/room/getHighlights", {
//     headers: {
//       Authorization: "Bearer " + token,
//     },
//   });
// };

// export const getTodayPerson = async () => {
//   const token = await AsyncStorage.getItem("@token_Key");

//   return await axios.get(SERVER_URL + "/api/room/getTodayPerson", {
//     headers: {
//       Authorization: "Bearer " + token,
//     },
//   });
// };

// export const getUser = async () => {
//   const token = await AsyncStorage.getItem('@token_Key');

//   return await axios.get(SERVER_URL + '/api/getUserProfileWithToken', {
//     headers: {
//       Authorization: 'Bearer ' + token,
//     },
//   });
// };

// export const getUpcomingEvent = async (userJson) => {
//   const token = await AsyncStorage.getItem('@token_Key');
//   return await axios.get(
//     SERVER_URL + '/api/room/getUpcomingEvent/' + userJson._id,
//     {
//       headers: {
//         Authorization: 'Bearer ' + token,
//       },
//     },
//   );
// };
