import { makeStyles } from "@material-ui/core/styles";
import {
  Router,
  Switch,
  Route,
  Redirect,
  withRouter,
  useNavigate,
} from "react-router-dom";

import React, { useState, useEffect, Suspense } from "react";
import { atom, useRecoilValue, useRecoilState } from "recoil";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import { loadCSS } from "fg-loadcss";
// import WriteStudyView from "../PostDevStudyView/PostDevStudyView";
import { useSelector, useDispatch } from "react-redux";
import { Button, Icon, Header } from "semantic-ui-react";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import * as actions from "../../redux/actions";
import { SERVER_URL } from "../../config/config";
import { StyledLink } from "baseui/link";
import { useStyletron } from "baseui";
import axios from "axios";
import { AppNavBar, setItemActive } from "baseui/app-nav-bar";
import { Blank } from "baseui/icon";
import {
  HeaderNavigation,
  ALIGN,
  StyledNavigationList,
  StyledNavigationItem,
} from "baseui/header-navigation";

//API
import { get_user_GET } from "./api/actions/query";

//State
import { userJsonRecoil } from "./api/state";

const token = "quanting_io_token";

function getToken(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function AppNavbarView(props, { children }) {
  const [userJson, setUserJson] = useRecoilState(userJsonRecoil);
  let navigate = useNavigate();

  console.log("App Nav Bar");

  useEffect(() => {
    const checkLogin = () => {
      console.log("checkLogin");
      if (document.cookie.includes(token)) {
        get_user_GET()
          .then((response) => {
            console.log("get user");
            console.log(response.data);
            setUserJson(response.data);

            if (response.data === null) {
              navigate("/welcome");
            } else {
              console.log("1 go mainview");
              navigate("/", response.data);
              console.log("2 go mainview");
            }
          })
          .catch((e) => {
            navigate("/welcome");
          });
      } else {
        navigate("/welcome");
      }

      // console.log(userJson);
      // navigate("/");
    };

    checkLogin();
  }, []);

  return (
    <React.Fragment>
      <HeaderNavigation
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              // borderBottomColor: "#2B4458",
              borderBottomColor: "#1F2A3E",
              outline: `${"#1F2A3E"} solid`,
              backgroundColor: "#1F2A3E",
            }),
          },
        }}
      >
        <StyledNavigationList $align={ALIGN.left}>
          <StyledNavigationItem>
            <StyledLink
              $style={{
                cursor: "pointer",
                textDecoration: "none",
                color: "inherit",
                ":hover": { color: "inherit" },
                ":visited": { color: "inherit" },
              }}
              onClick={() => props.history.push("/")}
            >
              <Header as="h2" style={{ color: "white" }}>
                Quanting.ai
              </Header>
            </StyledLink>
          </StyledNavigationItem>
        </StyledNavigationList>
        <StyledNavigationList $align={ALIGN.center} />

        {userJson === null ? (
          <StyledNavigationList $align={ALIGN.right}>
            <StyledNavigationItem>
              <Button
                icon="google"
                inverted
                content="Sign In"
                labelPosition="right"
                onClick={() =>
                  (window.location.href = SERVER_URL + "/users/auth/google?web")
                }
              ></Button>
            </StyledNavigationItem>
          </StyledNavigationList>
        ) : (
          <div>
            <Button
              inverted
              href="https://apps.apple.com/us/app/quanting-ai/id1592418343"
            >
              <Icon name="apple" />
              App Store
            </Button>

            <Button
              inverted
              href="https://play.google.com/store/apps/details?id=com.quanting_ai_rn"
            >
              <Icon name="google play" />
              Google Store
            </Button>
          </div>
        )}
      </HeaderNavigation>

      {/* path: "/users/auth/google", */}
      {/* label: "Sign In", */}
    </React.Fragment>
  );
}

export default AppNavbarView;
