import { atom } from "recoil";

export const userJsonRecoil = atom({
  key: "userJsonRecoil",
  default: null,
});

// const recoilStar = selector({
//   key: 'recoil/star',
//   get: async () => {
//     const response = await fetch(
//       'https://api.github.com/repos/facebookexperimental/Recoil',
//     );
//     const recoilProjectInfo = await response.json();
//     return recoilProjectInfo.stargazers_count;
//   },
// });

// export const filteredHighlightsRoomJsonRecoil = selector({
//   key: 'filteredHighlightsRoomJsonRecoil',
//   get: ({get}) => {
//     const highlightsRoomJson = get(highlightsRoomJsonRecoil);
//     return highlightsRoomJson;
//     // return animals.filter((animal) => animal.type === filter);
//   },
// });

// export const myPaidRoom  = selector({
//   key: 'noteList',
//   get: async ({get}) => {
//     // force update cached data based on user
//     get(userAuth);
//     get(noteUpdatedOn);

//     let notes = [];

//     try {
//       const {data} = await list();

//       if (data && data.success) {
//         notes = data.list;
//       }
//     } catch (e) {
//       console.log(e.message);
//     }

//     return notes;
//   },
// });
