import React from "react";
import { useState, useEffect, useContext } from "react";

import { SERVER_URL } from "../config/config";
import { Container, Divider, Header } from "semantic-ui-react";
import { Icon, Button, Label } from "semantic-ui-react";

import {
  Router,
  Switch,
  Route,
  Redirect,
  withRouter,
  useNavigate,
} from "react-router-dom";

import {
  Grommet as GrommetIcon,
  FacebookOption,
  Instagram,
  Twitter,
} from "grommet-icons";

import { Anchor, Box, Footer, grommet, Grommet, Main, Text } from "grommet";

import imageBG from "../image/AI_chart_trends_2020_banner.jpeg";
import ContainerMaterial from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Flex, Image } from "rebass";

import { Favorite, ShareOption } from "grommet-icons";
import { PieChart } from "react-minimal-pie-chart";

import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

//API
import {
  get_predict_POST,
  search_stock_with_name_POST,
} from "./Main/api/actions/query";

// #EF5350
// #862226
// #444651
// #133A87
// #2862FF

function getColor(params) {
  switch (params) {
    case "STRONG_BUY":
    case "BUY":
      return "#41FC9E";

    case "NEUTRA":
      return "#444651";

    case "STRONG_SELL":
    case "SELL":
      return "#F82642";

    default:
      break;
  }
}

const sample_data = [
  { symbol: "TSLA", screener: "america", exchange: "NASDAQ", interval: "1d" },
  { symbol: "AAPL", screener: "america", exchange: "NASDAQ", interval: "1d" },
  { symbol: "NFLX", screener: "america", exchange: "NASDAQ", interval: "1d" },
  { symbol: "005930", screener: "korea", exchange: "KRX", interval: "1d" },
  { symbol: "QQQ", screener: "america", exchange: "NASDAQ", interval: "1d" },
  { symbol: "AMZN", screener: "america", exchange: "NASDAQ", interval: "1d" },
  { symbol: "AMD", screener: "america", exchange: "NASDAQ", interval: "1d" },
  { symbol: "NVDA", screener: "america", exchange: "NASDAQ", interval: "1d" },
  { symbol: "SPOT", screener: "america", exchange: "NYSE", interval: "1d" },
  { symbol: "NET", screener: "america", exchange: "NYSE", interval: "1d" },
  // { symbol: "TSLA", screener: "america", exchange: "NASDAQ", interval: "1d" },
];

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const WelcomeView = (props) => {
  const classes = useStyles();

  // State
  const [predict_json, set_predict_json] = React.useState([]);
  const [search_stock_string, set_search_stock_string] =
    React.useState("intel");

  let requestData = () => {
    sample_data.map((param) =>
      get_predict_POST(param).then((response) => {
        set_predict_json((predict_json) => [...predict_json, response.data]);
      })
    );
  };

  let search_stock_with_name = () => {
    search_stock_with_name_POST(search_stock_string).then((response) => {
      console.log(response.data);
      return response.data;
    });
  };

  useEffect(() => {
    console.log("useEffect");

    // console.log(search_stock_with_name());

    // requestData();
  }, []);

  return (
    <div style={{ backgroundColor: "#1F2A3E" }}>
      <section
        className="section is-primary is-medium"
        // style={{ backgroundColor: "#EFF0F2" }}
      >
        <ContainerMaterial
          // maxWidth="xm"
          component="main"
          className={classes.heroContent}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 40,
              backgroundColor: "#1F2A3E",
            }}
          >
            <Image
              src={imageBG}
              sx={{
                width: ["100%", "50%"],
                height: ["100%", "70%"],
                maxWidth: 1000,
                borderRadius: 8,
              }}
              onClick={() =>
                (window.location.href = SERVER_URL + "/users/auth/google?web")
              }
            />
          </div>

          <Container text>
            <Header style={{ color: "white", marginTop: 20 }} as="h2">
              Stock Technical Analysis
            </Header>
            <p>
              You can easily see the value evaluated from 0 to 100 by scoring 26
              indicators.
            </p>

            <p>
              Quantitative analysis is the use of mathematical and statistical
              methods in finance and investment management. Those working in the
              field are quantitative analysts (quants). Quants tend to
              specialize in specific areas which may include derivative
              structuring or pricing, risk management, algorithmic trading and
              investment management.
            </p>

            <p>
              The occupation is similar to those in industrial mathematics in
              other industries. The process usually consists of searching vast
              databases for patterns, such as correlations among liquid assets
              or price-movement patterns (trend following or mean reversion).
              The resulting strategies may involve high-frequency trading.
            </p>
            <p>
              Trading (especially using an automated program) is a dangerous
              activity. Do not use TradingView's analysis to trade automatically
              without your supervision. I am not responsible for any financial
              loss.
            </p>
          </Container>

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
          >
            <Button
              style={{ backgroundColor: "#DC004E", color: "white" }}
              size="large"
              onClick={() =>
                (window.location.href = SERVER_URL + "/users/auth/google?web")
              }
            >
              <Icon name="terminal" />
              Login Quanting.ai
            </Button>
          </div>
        </ContainerMaterial>
      </section>

      <Container style={{ marginTop: 20 }}></Container>

      <Footer justify="center" pad="small">
        <Text textAlign="center" size="small">
          © 2021 Copyright Quanting.ai
        </Text>
      </Footer>
    </div>
  );
};

export default WelcomeView;

// #EF5350
// #862226
// #444651
// #133A87
// #2862FF

// import ContainerMaterial from "@material-ui/core/Container";
// import React, { useState, useEffect, useCallback } from "react";
// import { Flex, Image } from "rebass";
// import "./styles.css";

// import { SERVER_URL, ML_URL } from "../config/config";
// import { connect } from "react-redux";
// import { bindActionCreators, compose } from "redux";
// import * as actions from "../redux/actions";

// import { makeStyles } from "@material-ui/core/styles";
// import CardWelcomeView from "./CardView/CardWelcomeView";
// import { Container, Divider, Header } from "semantic-ui-react";

// import { Icon, Button, Label } from "semantic-ui-react";

// import {
//   Grommet as GrommetIcon,
//   FacebookOption,
//   Instagram,
//   Twitter,
// } from "grommet-icons";

// import { Anchor, Box, Footer, grommet, Grommet, Main, Text } from "grommet";
// import Typography from "@material-ui/core/Typography";

// import {
//   Router,
//   Route,
//   IndexRoute,
//   Link,
//   IndexLink,
//   browserHistory,
//   hashHistory,
//   BrowserRouter,
//   Redirect,
//   withRouter,
// } from "react-router-dom";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCoffee,
//   coffee,
//   apple,
//   faCheckSquare,
//   faHome,
// } from "@fortawesome/free-solid-svg-icons";

// import { useTransition, animated } from "react-spring";

// const useStyles = makeStyles((theme) => ({
//   "@global": {
//     body: {
//       backgroundColor: theme.palette.common.white,
//     },
//     ul: {
//       margin: 0,
//       padding: 0,
//     },
//     li: {
//       listStyle: "none",
//     },
//   },
//   appBar: {
//     borderBottom: `1px solid ${theme.palette.divider}`,
//   },
//   toolbar: {
//     flexWrap: "wrap",
//   },
//   toolbarTitle: {
//     flexGrow: 1,
//   },
//   link: {
//     margin: theme.spacing(1, 1.5),
//   },
//   heroContent: {
//     padding: theme.spacing(8, 0, 6),
//   },
//   cardHeader: {
//     backgroundColor: theme.palette.grey[200],
//   },
//   cardPricing: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "baseline",
//     marginBottom: theme.spacing(2),
//   },
//   footer: {
//     borderTop: `1px solid ${theme.palette.divider}`,
//     marginTop: theme.spacing(8),
//     paddingTop: theme.spacing(3),
//     paddingBottom: theme.spacing(3),
//     [theme.breakpoints.up("sm")]: {
//       paddingTop: theme.spacing(6),
//       paddingBottom: theme.spacing(6),
//     },
//   },
// }));

// const Media = () => (
//   <Box direction="row" gap="xxsmall" justify="center">
//     <Anchor
//       a11yTitle="Share feedback on Github"
//       href="https://www.instagram.com/"
//       icon={<Instagram color="brand" />}
//     />
//     <Anchor
//       a11yTitle="Chat with us on Slack"
//       href="https://www.facebook.com/"
//       icon={<FacebookOption color="brand" />}
//     />
//     <Anchor
//       a11yTitle="Follow us on Twitter"
//       href="https://twitter.com/"
//       icon={<Twitter color="brand" />}
//     />
//   </Box>
// );

// function Welcome(props) {
//   const classes = useStyles();

//   useEffect(() => {
//     // if (
//     //   props.authUser === null &&
//     //   props.location.pathname.includes("/welcome") === true
//     // ) {
//     //   // props.history.push("/welcome");
//     // } else {
//     //   if (props.authUser?.status === "fail") {
//     //     // props.history.push("/welcome");
//     //     if (props.location.pathname.includes("/welcome") !== true) {
//     //       props.history.push("/welcome");
//     //     }
//     //   } else {
//     //     // props.history.push("/main");
//     //     props.history.push("/");
//     //   }
//     // }
//   });

//   return (
//     <div>
//       <section
//         className="section is-primary is-medium"
//         // style={{ backgroundColor: "#EFF0F2" }}
//       >
//         <ContainerMaterial
//           // maxWidth="xm"
//           component="main"
//           className={classes.heroContent}
//         >
//           {/* <Typography
//             variant="h5"
//             align="center"
//             color="textPrimary"
//             gutterBottom
//           >
//             ReadBooks.ai
//           </Typography>
//           <Typography
//             variant="h6"
//             align="center"
//             color="textSecondary"
//             component="p"
//           >
//             Meet someone with knowledge.

//           </Typography> */}

//           <div
//             style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
//           >
//             <Image
//               src="https://quicksilvertranslate.com/wp-content/uploads/iu-34.jpeg"
//               sx={{
//                 width: ["100%", "50%"],
//                 height: ["100%", "70%"],
//                 maxWidth: 1000,
//                 borderRadius: 8,
//               }}
//               onClick={() =>
//                 (window.location.href = SERVER_URL + "/users/auth/google?web")
//               }
//             />
//           </div>

//           <div
//             style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
//           >
//             <Button
//               style={{ backgroundColor: "#DC004E", color: "white" }}
//               size="large"
//               onClick={() =>
//                 (window.location.href = SERVER_URL + "/users/auth/google?web")
//               }
//             >
//               <Icon name="terminal" />
//               Login ReadBooks.ai
//             </Button>
//           </div>
//         </ContainerMaterial>
//       </section>

//       {/* <div
//         className="simple-trans-main"
//         onClick={onClick}
//         style={{ height: "10%", width: "10" }}
//       >
//         {transitions.map(({ item, props, key }) => {
//           const Page = pages[item];
//           return <Page key={key} style={props} />;
//         })}
//       </div> */}

//       <Container style={{ marginTop: 20 }}>
//         {/* <Box align="center" height="small" width="large"> */}
//         {/* <div
//           style={{
//             display: "flex",
//             justifyContent: "center"
//           }}
//         >
//           <img style={{ width: 1000, height: 400 }} src={require("../1.png")} />
//         </div> */}
//         {/* <div
//           style={{
//             display: "flex",
//             justifyContent: "center"
//           }}
//         >
//           <img style={{ width: 1000, height: 400 }} src={require("../2.png")} />
//         </div> */}
//         {/* <div
//           style={{
//             display: "flex",
//             justifyContent: "center"
//           }}
//         >
//           <img
//             style={{ width: 1000, height: 1000 }}
//             src={require("../3.png")}
//           />
//         </div> */}
//       </Container>
//       <Footer justify="center" pad="small">
//         <Text textAlign="center" size="small">
//           © 2021 Copyright Quanting.ai
//         </Text>
//       </Footer>
//     </div>
//   );
// }

// export default compose(
//   withRouter,
//   connect(
//     (state) => state,
//     (dispatch) => ({
//       actions: bindActionCreators(actions, dispatch),
//     })
//   )
// )(Welcome);

// export default withRouter(Welcome);
